div.shareButton {
  position: absolute;
  right: 4rem;
  bottom: 3rem;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #ccc;
}

div.shareButton img {
  position: relative;
  max-width: 50%;
  max-height: 50%;
  left: 50%;
  top: 47%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 768px) {
  div.shareButton {
    right: 1rem;
    bottom: 0rem;
  }
}